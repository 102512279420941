<template>
	<div class="cs-cookie-banner cs-block cs-no-padding" v-if="show">
		<div class="cs-block-base">
			<div class="container">
				<CSCookieBanner :data="data" />
			</div>
		</div>
	</div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
	props: {
		data: {
			type: Object,
			default: () => {},
		},
	},
	components: {
		CSCookieBanner: () => import("../components/CSCookieBanner.vue"),
	},
	data() {
		return {
			show: true,
		}
	},
	mounted() {
		if (typeof window != "undefined") {
			this.show = !this.cookies().display()
		}
	},
    computed: computed('CookieBanner')
}
</script>
<style scoped lang="scss">
@import "../styles/main.scss";
.cs-cookie-banner {
	padding: 30px 0 !important;
	position: fixed !important;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #eef3f9;
    z-index: 101;


	::v-deep {
        .action-area {
            display: flex;
            @media (max-width:991px) {
                flex-direction: column;
            }
            .text-content {
                width: 75%;
                padding-right: 25px;
                @media (max-width: 991px) {
                    width: 100%;
                    padding: 0px;
                }
                span.cs-text {
                    @media (max-width: 991px) {
                        max-height: 240px;
                        overflow: scroll;
                    }
                }
            }

            .button-area {
                width: 30%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                @media (max-width: 991px) {
                    width: 100%;
                    flex-direction: row;
                    justify-content: flex-start;
                    margin-top: 15px;
                    align-items: center;
                    gap: 20px;
                }

                .cs-checkbox {
                    margin-bottom: 10px;
                    span {
                        display: flex;
                        input {
                            cursor: pointer;
                            margin-right: 15px;
                        }
                        label {
                            @include text;
                            margin: 0;
                        }
                    }
                }
                .buttons {
                    .button-group {
                        a {
                            @include base-transition;
                            color: $secondary_text_color;
                            padding: 15px;
                            text-decoration: none;
                            text-align: center;
                            border: 1px solid $theme_color;
                            background: $theme_color;
                            font-weight: 500;
                            cursor: pointer;
                            min-width: 130px;
                            display: flex;
                            justify-content: center;
                            border-radius: 0 !important;
                            &:hover {
                                border-color: $theme_color !important;
                                background: transparent !important;
                                color: $secondary_text_color !important;
                            }
                        }
                    }
                    .button-group:nth-child(even) a {
                        color: $secondary_text_color !important;
                        border: 1px solid $theme_color !important;
                        background: transparent !important;

                        &:hover {
                            background: $theme_color !important;
                            color: #fff !important;
                        }
                    }
                }
            }
        }
    }
}
</style>